@import "../../node_modules/@clr/ui/clr-ui.min.css";
.in-overflow .tabs-overflow > .nav-item > button {
  box-shadow: 0 -3px 0 #0077b8 inset;
  color: #0077b8;
}

.label-form {
  background-color: #eee !important;
}

.esxc-gauge-circle-bgc-dark {
  background-color: #fff;
}

.harbor-icon {
  transform: translateX(100%) !important;
  filter: drop-shadow(#000 -56px 2px) !important;
}

.color-white-dark {
  color: #1d5100;
}

.label-filter-panel {
  background-color: #fff !important;
}

.filter-dark {
  color: #313131 !important;
}

.side-form {
  background-color: #fff !important;
}

.toolBar {
  background-color: #fff !important;
}

.search-overlay {
  background-color: #fafafa !important;
}

.add-modal-dark {
  background: #eee !important;
}

.license {
  background-color: #fff !important;
}

.pagination-list .pagination-current {
  color: #313131 !important;
}

.no-info-div,
.info-div {
  background-color: #fff !important;
}

.bottom-line-project-config {
  color: #000 !important;
}

.config-subtext {
  color: unset !important;
}

.permission-dark {
  color: #000 !important;
}

.scanner-name {
  color: #000 !important;
}

.summary-dark {
  color: #000 !important;
}
.summary-dark h5 {
  color: #000 !important;
}

.about-version {
  color: #565656 !important;
}

.update-time {
  color: #000 !important;
}

.font-style {
  color: #000 !important;
}

.go-link {
  color: blue !important;
  border-bottom: 1px solid blue !important;
}

.circle-block {
  color: #575757 !important;
}

.executions-detail label {
  color: #000 !important;
}

.black-point {
  background-color: #000 !important;
}

clr-dg-action-overflow clr-icon {
  fill: #000 !important;
}

.oidc-tip {
  color: #000 !important;
}

.clr-select-wrapper select option {
  background: #fff;
  color: #1b2a32;
}

hbr-tag .color-green {
  color: #1d5100 !important;
}

.filter-divider {
  background-color: #ccc;
}

.selectBox {
  background: #fff !important;
  color: #1b2a32 !important;
}

.selectBox ul li:hover {
  background-image: linear-gradient(180deg, #f5f5f5 0, #e8e8e8) !important;
}

.btn-tag-integration .active {
  background: #0077b8;
  color: #fff;
}

hbr-result-tip-histogram .inner {
  background-color: #fff;
}

.md-div pre:not([class*=language-]) code:not([class*=language-]), .md-div code:not([class*=language-]) {
  background: #ddd;
  border-radius: 2px;
  padding: 2px 4px;
}

:not(pre) > code[class*=language-], pre[class*=language-] {
  background-color: none;
}

.md-div code:not([class*=language-]) {
  color: #657b83;
}
.md-div pre:not([class*=language-]) {
  background: #fdf6e3;
}
.md-div pre:not([class*=language-]) code:not([class*=language-]) {
  background: transparent;
}
.md-div table {
  display: block;
  width: 100%;
  overflow: auto;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 16px;
}
.md-div table td,
.md-div table th {
  padding: 6px 13px;
  border: 1px solid #ddd;
}
.md-div table td[align="$var"],
.md-div table th[align="$var"] {
  text-align: left;
}
.md-div table td[align="$var"],
.md-div table th[align="$var"] {
  text-align: right;
}
.md-div table td[align="$var"],
.md-div table th[align="$var"] {
  text-align: center;
}
.md-div table tr:nth-child(2n) {
  background-color: #f2f2f2;
}

.table-tag .tag-thead .tag-header-color {
  color: #eee;
}
.table-tag .tag-tbody .tag-tr .tag-body-color {
  color: #fafafa;
}

clr-header {
  background-color: #004a70;
}