// Variables for light theme should be defined here.
@import "../../node_modules/@clr/ui/clr-ui.min.css";

  
$mode-background-color: #fff;
$mode-background-color1: $mode-background-color;
$mode-background-color2: #fafafa;
$mode-background-color3: #eee;
$fill-color1: #000;

$mode-font-color1: #313131;
$mode-font-color3: $fill-color1;
$mode-font-color4: unset;
$mode-font-color5: #575757;
$mode-font-color2: #565656;
$mode-link-color1: blue;
$mode-link-color2: #0077b8;
$select-option-color: #1b2a32;
$select-back-color: $mode-background-color;

$label-form-color: $mode-background-color3;
$right-status-fill-color: #1d5100;
$light-color-green: $right-status-fill-color;

$color-ddd: #ddd;
$color-f2: #f2f2f2;
$color-657b83: #657b83;
$color-fdf6e3:  #fdf6e3;
$header-color: rgb(0, 74, 112);
$color-yaml:  none;
$filter-divider-bg-color: #ccc;
$selectBox-option-hover-bg-color-start: #f5f5f5;
$selectBox-option-hover-bg-color-end: #e8e8e8;
$btn-tag-integration-active-bg-color: #0077b8;
$btn-tag-integration-active-color: #fff;
$hbr-result-tip-histogram-inner-bg-color: #fff;

$harbor-icon-translate-x: 100%;
$harbor-icon-drop-shadow-x: -56px;

@import "./common.scss";
